.login-logo {
  height: 160px;
  padding: 10px;
  padding-bottom: 50px;
}

.login-logo-div {
  text-align: center;
}

.panel {
  box-shadow: 0 0 15px 8px #888;
  background: #3a3a3a;
  border-color: transparent;
  color: #fff;
}

.panel-default > .panel-heading {
  color: #fff;
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  background: transparent;
  border: none;
}